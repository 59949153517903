import React from 'react';
import Image from 'next/image';
import gshLogo from "../../public/svgs/breezeup-logo.svg";

export default function Footer() {
 const foter = new Date().getFullYear();
  return (
    <div className="bg-[#000] w-full md:h-[100px] h-[59px] text-[#ffff] font-cormorant md:pl-[100px] pl-[20xp] md:pt-[30px] pt-[15px] buttom-0">
      <ul className='flex md:gap-[350px] gap-[50px] items-center'>
       <li className='flex items-center'><Image src={gshLogo} height={50} width={50} alt="logo" className='md:h-[50px] md:w-[50px] w-[25px] h-[25px]'/> <span className='md:text-[25px] text-[16px] ml-[5px]'> breezeup</span></li>
       <li className='flex items-center'>
          <span className='md:text-[16px] text-[10px]'>
           © all Copyright reserved &nbsp;
            {foter}
            &nbsp;
          </span>
        </li>
      </ul>
    </div>
  )
}
