"use client";

import React, { useState } from "react";
import Link from "next/link";
import HamburgerIcon from "../../public/svgs/hamburger-menu.svg";
import Image from "next/image";
// import gshLogo from "../../public/svgs/gshLogo.svg";
import gshLogo from "../../public/svgs/breezeup-logo.svg";
import arrow from "../../public/svgs/nav-right.svg";
import { usePathname } from "next/navigation";
import { useSession } from "next-auth/react";
import { handleSignOut } from "@/src/context/UserContext";

const NavBar = () => {
  const [openNavbar, setOpenNavbar] = useState(false);
  const pathname = usePathname();
  const { data: session } = useSession();

  const navMenus = session
    ? [
        { name: "My Matches", path: "/matches" },
        { name: "Search", path: "/search" },
        { name: "Profile", path: `/user/${session?.user?.id}/profile/edit` },
      ]
    : [
        { name: "My Matches", path: "/matches" },
        { name: "Search", path: "/search" },
        { name: "Login", path: "/auth/signin" },
      ];

  return (
    <nav className="bg-white w-full relative font-cormorant flex justify-center">
      <div
        className={`fixed inset-0 z-10 transition-opacity duration-300 ${
          openNavbar
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="absolute inset-0 bg-white opacity-3 backdrop-blur-sm mix-blend-multiply"></div>
      </div>

      <div className="bg-white flex items-center justify-between mx-auto py-3 md:px-1 lg:mx-0 lg:px-[100px] fixed z-20 w-full top-0">
        <Link href="/" className="flex items-center gap-[5px]">
          <Image
            src={gshLogo}
            alt="gsh Logo"
            className="w-[35px] md:w-[59px] h-[25px] md:h-[47px] lg:w-[59px]"
          />
          <h1 className="md:text-[35px] text-[20px] text-[#1E1E1E] font-garamond">
            breezeup
          </h1>
        </Link>
        <div className="md:hidden">
          <button
            className="p-2 text-gray-700 rounded-md outline-none"
            onClick={() => setOpenNavbar(!openNavbar)}
          >
            {openNavbar ? (
              <span className="text-primary text-[40px] font-bold">
                &times;
              </span>
            ) : (
              <Image src={HamburgerIcon} alt="Hamburger Icon" />
            )}
          </button>
        </div>
        <div
          className={`w-full md:block md:w-auto ${
            openNavbar
              ? "w-full flex flex-col gap-3 z-20 pb-7 bg-white absolute top-16 right-0 md:relative h-[400px]"
              : "hidden"
          }`}
        >
          <ul
            className={`items-center justify-between  ${
              session ? "gap-[50px]" : "gap-[150px]"
            } space-y-4 p-4 md:pl-0 md:flex md:space-x-3 lg:space-x-2 md:space-y-0 md:ml-[100px] ml-0`}
          >
            {navMenus.map(({ name, path }, index) => (
              <li
                key={index}
                className={`relative flex ${
                  openNavbar &&
                  path === "/auth/signin" &&
                  "w-[339px] h-[40px] border-[1px] border-[#000] pl-[140px]"
                } justify-between items-center`}
              >
                <Link
                  href={path}
                  className={`flex items-center py-2 pl-3 pr-4 rounded justify-between ${
                    pathname === path
                      ? "md:text-primary text-[18px] font-semibold leading-6 text-[#000]"
                      : `text-[#aaa7a7] ${
                          pathname === "/auth/signin"
                            ? "text-center"
                            : "md:text-[#aaa7a7]"
                        } md:text-sm lg:text-lg`
                  } hover:bg-gray-100 hover:text-primary md:hover:bg-transparent md:p-0`}
                  aria-current={pathname === path ? "page" : undefined}
                  onClick={() => setOpenNavbar(false)}
                >
                  {name}
                </Link>
                {openNavbar && path !== "/auth/signin" ? (
                  <Image src={arrow} width={7} height={12} alt="arrow" />
                ) : null}
              </li>
            ))}
          </ul>
          {session ? (
            <>
              <button
                className="ml-5 justify-center items-center text-[#aaa7a7] text-primary md:hidden w-[329px] h-[40px] border-[1px] border-[#000]"
                onClick={handleSignOut}
              >
                Sign Out
              </button>
              <Link
                className="ml-5 w-[150px] py-[10px] justify-center items-center bg-white text-primary rounded-[10px] md:hidden"
                href="/upgrade"
              >
                <button
                  type="button"
                  className="text-black rounded-[3px] bg-[#FFBF00] text-[12px] font-medium w-[329px] h-[40px]"
                  onClick={() => setOpenNavbar(false)}
                >
                  Upgrade Account
                </button>
              </Link>
            </>
          ) : (
            <Link
              className="ml-5 w-[150px] py-[10px] justify-center items-center bg-white text-primary rounded-[10px] md:hidden"
              href="/auth/register/register-email"
            >
              <button
                type="button"
                className="text-black rounded-[3px] bg-[#FFBF00] text-[12px] font-medium w-[339px] h-[40px] mt-[-20px]"
              >
                Sign Up
              </button>
            </Link>
          )}
        </div>
        {session ? (
          <div className="md:flex space-x-2 hidden">
            <button
              className="py-[10px] px-[24px] md:px-[20px] justify-center items-center bg-primary text-[#aaa7a7] font-bold rounded-[10px]"
              onClick={handleSignOut}
            >
              Sign Out
            </button>
            <Link
              className="py-[10px] px-[24px] md:px-[20px] justify-center items-center bg-primary text-white rounded-[10px]"
              href="/upgrade"
            >
              <button
                type="button"
                className="text-black h-[65px] w-[166px] rounded-[5px] bg-[#FFBF00] text-[16px] font-medium"
              >
                Upgrade Account
              </button>
            </Link>
          </div>
        ) : (
          <Link
            className="md:flex py-[10px] px-[24px] md:px-[20px] justify-center items-center bg-primary text-white rounded-[10px] hidden"
            href="/auth/register/register-email"
          >
            <button
              type="button"
              className="text-black h-[65px] w-[166px] rounded-[5px] bg-[#FFBF00] text-[16px] font-medium"
            >
              Sign Up
            </button>
          </Link>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
