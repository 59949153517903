// "use client";

import {
  createContext,
  useReducer,
  useEffect,
  useState,
  useContext,
} from "react";
import { fetchUsers } from "../actions/fetchUsers";
import { signOut } from "next-auth/react";
import { clearSessionCache } from "@/lib/storage";
import signOutUser from "../actions/signOutUser";

const initialState = {
  users: [],
  currentPage: 0,
  totalPages: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_users":
      return {
        ...state,
        users: action.payload.users,
        totalPages: action.payload.totalPages,
      };
    case "set_page":
      return {
        ...state,
        currentPage: action.payload,
      };
    default:
      return state;
  }
};

const UserContext = createContext();

const UserProvider = ({ children, cUser }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [sessionUser, setSessionUser] = useState(undefined);

  const loadUsers = async (page = 0) => {
    try {
      const { users, totalPages } = await fetchUsers(page);
      dispatch({ type: "set_users", payload: { users, totalPages } });
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    loadUsers(state.currentPage);
  }, [state.currentPage]);

  const setPage = (page) => {
    dispatch({ type: "set_page", payload: page });
  };

  return (
    <UserContext.Provider
      value={{ sessionUser: cUser, state, setPage, loadUsers, setSessionUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };

export const useUserContext = () => useContext(UserContext);

export const withUserContext = (Component) => {
  // eslint-disable-next-line react/display-name
  return (...props) => {
    return <Component {...props} {...useUserContext()} />;
  };
};

export const handleSignOut = async () => {
  try {
    clearSessionCache();
    signOut();
    signOutUser();
  } catch (err) {
    console.log(err.response?.data?.message || err.message);
  }
};
