"use client";

import React from "react";
import { NextUIProvider } from "@nextui-org/react";
import { UserProvider } from "../context/UserContext";
import { SessionProvider } from "next-auth/react";

async function Providers({ children, session }) {
  return (
    <SessionProvider session={session}>
      <UserProvider cUser={session?.user}>
        <NextUIProvider>{children}</NextUIProvider>
      </UserProvider>
    </SessionProvider>
  );
}

export default Providers;
