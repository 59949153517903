"use client";

import React from "react";
import { usePathname } from "next/navigation";
import NavBar from "./NavBar";
import Footer from "../footer/Footer";

export default function ConditionalLayout({ children }) {
  const pathname = usePathname();
  return (
    <React.Fragment>
      {pathname.startsWith("/dashboard") || pathname.startsWith("/auth") ? (
        children
      ) : (
        <div className="flex flex-col min-h-screen h-screen">
          <NavBar />
          <main
            className="
            mt-[70px] flex-1 md:mt-[150px] w-full mb-12
            "
          >
            {children}
          </main>
          <Footer />
        </div>
      )}
    </React.Fragment>
  );
}
